import * as React from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import { Roles } from "../models/user/user.models";
import { RouteNames } from "../constants/RouteNames";
import { useUserRole } from "../store/reducers/user/user.reducer";
import { useTypedSelector } from "../store/store.utils";

type RoleAccessRouteArguments = {
  [role in Roles]?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const roleAccessRoute = (options: RoleAccessRouteArguments): React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> => {
  let Component;
  return props => {
    const userInfo = useTypedSelector(state => state.user.userInfo);
    const history = useHistory();
    const userRole = useUserRole();
    Component = userRole ? options[userRole] : null;

    useEffect(() => {
      if (userRole && !options[userRole]) {
        history.push(`/${RouteNames.ROOT}`);
      }
    }, [userRole, history]);

    useEffect(() => {
      if (!userInfo.loading && !userInfo.data) {
        history.push(`/${RouteNames.WELCOME}`);
      }
    }, [userInfo, history]);


    return Component ? <Component {...props}/> : null;
  };
};

