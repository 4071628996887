import { action } from "typesafe-actions";


export enum ActionNames {
  OPEN = "[Snackbar] Open",
  CLOSE = "[Snackbar] Close",
}


export class SnackbarActions {
  static open(message: string) {
    return action(ActionNames.OPEN, { message });
  };

  static close() {
    return action(ActionNames.CLOSE);
  };
}


