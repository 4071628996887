import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "./react-auth0-spa";
import { ThemeProvider } from "@material-ui/core";
import { materialTheme } from "./styles/material-theme.config";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "./config/axios.config";


ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider theme={materialTheme}>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0Provider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App/>
          </MuiPickersUtilsProvider>
        </Auth0Provider>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  // </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorker.unregister();
