import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styles from "../Header.module.scss";
import { RouteNames } from "../../../constants/RouteNames";
import classNames from "classnames";
import { Avatar, Menu, MenuItem } from "@material-ui/core";
import { useTypedSelector } from "../../../store/store.utils";
import { useAuth0 } from "../../../react-auth0-spa";

const linkArray = [
  { name: "About", href: `/${RouteNames.WELCOME}#about` },
  { name: "How it Works", href: `/${RouteNames.WELCOME}#how-it-works` },
  { name: "Features", href: `/${RouteNames.WELCOME}#features` },
  { name: "Prices", href: `/${RouteNames.WELCOME}#prices` },
  { name: "Testimonials", href: `/${RouteNames.WELCOME}#testimonials` },
  { name: "FAQ", href: `/${RouteNames.WELCOME}#faq-section` },
];

export const HeaderNavigation = () => {
  const userInfo = useTypedSelector(state => state.user.userInfo.data);
  const location = useLocation();
  const history = useHistory();
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openProfileMenuHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeProfileMenuHandler = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    closeProfileMenuHandler();
    logout();
  };

  const redirectToDashboardHandler = () => {
    closeProfileMenuHandler();
    history.push(`/${RouteNames.ROOT}`);
  };


  return (
    <div className={classNames(styles.HeaderNavigationContainer)}>
      <ul className={styles.HeaderNavigationList}>
        {
          linkArray.map((link, index) =>
            <li key={index}
                className={classNames({
                  [styles.ActiveLink]: location.pathname === link.href,
                })}
            >
              <Link to={link.href}>{link.name}</Link>
            </li>,
          )
        }
        {
          userInfo
            ? <li>

              <div className={styles.ProfileLink} onClick={openProfileMenuHandler}>
                <span>{userInfo.name || 'no name'}</span>
                <Avatar src={userInfo.avatarUrl}
                        classes={{
                          root: styles.Avatar,
                        }}
                />
              </div>
              <Menu
                id="header-profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeProfileMenuHandler}
              >
                <MenuItem onClick={logoutHandler}>Log Out</MenuItem>
                <MenuItem onClick={redirectToDashboardHandler}>Dashboard</MenuItem>
              </Menu>
            </li>
            : <li className={classNames({
              [styles.ActiveLink]: location.pathname === `/${RouteNames.LOGIN}`,
            })}>
              <Link className="bold-text" to={`/${RouteNames.LOGIN}`}>Login</Link>
            </li>
        }
      </ul>
    </div>
  );
};
