import React from "react";
import BlackLogo from "./images/blackLogo.png";
import DateIcon from "./images/calendar.png";
import styles from './index.module.scss';

const View: React.FC<{
  className?: string
  style?: string
}> = ({ children, className, style }) => {
  return (
    <div className={className || style}>{children}</div>
  )
}

const Document = View;

const Image: React.FC<{
  style?: string
  src?: string
}> = ({ style, src }) => {
  return (
    <img className={style} src={src} alt="icon" />
  )
}

const Link: React.FC<{
  style?: string
  src?: string
}> = ({ style, src, children }) => {
  return (
    <a className={style} href={src} target="_blank" rel="noreferrer">{children}</a>
  )
}

const Text = View;

type Props = {
  name: string,
  registrationDate: string,
  creationDate: string,
  author: string,
  coAuthors: string[],
  fileName: string,
  blockchainLink: string,
  fileHash: string
};

export const HTMLCopyrightCertificate: React.FC<Props> = (props) => {
  return (
    <Document>
      <div className={styles.page}>
        <View className={styles.innerContainer}>
          <View className={styles.logoWrapper}>
            <Image
              src={BlackLogo}
            />
          </View>
          <Text style={styles.title}>Copyright Certificate</Text>
          <View className={styles.songNameContainer}>
            <Text style={styles.songNameTitle}>Song title</Text>
            <Text style={styles.songName}>{props.name}</Text>
          </View>
          <View className={styles.dateWrapper}>
            <View className={styles.dateContainer}>
              <View className={styles.dateIconWrapper}>
                <Image
                  style={styles.dateIcon}
                  src={DateIcon}
                />
              </View>
              <Text style={styles.createdDate}>Registered on {props.registrationDate}</Text>
            </View>
          </View>
          <View className={styles.dateWrapper}>
            <View className={styles.dateContainer}>
              <View className={styles.dateIconWrapper}>
                <Image
                  style={styles.dateIcon}
                  src={DateIcon}
                />
              </View>
              <Text style={styles.createdDate}>Created on {props.creationDate}</Text>
            </View>
          </View>
          <View className={styles.detailsContainer}>
            <View className={styles.detailsBlock}>
              <Text style={styles.detailsBlockTitle}>Author & Co-Author Details</Text>
              <View className={styles.fieldsWrapper}>
                <View className={styles.fieldContainer}>
                  <Text style={styles.fieldTitle}>Author Full Name</Text>
                  <Text style={styles.fieldValue}>{props.author}</Text>
                </View>
                <View className={styles.fieldContainer}>
                  <Text style={styles.fieldTitle}>Co -Authors Full Name</Text>
                  <Text style={styles.fieldValue}>{props.coAuthors.join(", ")}</Text>
                </View>
              </View>
            </View>
            <View className={styles.detailsBlock}>
              <Text style={styles.detailsBlockTitle}>Other Details</Text>
              <View className={styles.fieldsWrapper}>
                <View className={styles.fieldContainerRight}>
                  <Text style={styles.fieldTitle}>File Name</Text>
                  <Text style={styles.fieldValue}>{props.fileName}</Text>
                </View>
                <View className={styles.fieldContainerRight}>
                  <Text style={styles.fieldTitle}>File Hash String</Text>
                  <Text
                    style={styles.fieldValue}>{props.fileHash.replace(/([^ ]{30})/g, "$1 ")}</Text>
                </View>
              </View>
            </View>
          </View>
          <View className={styles.fieldContainerRight}>
            <Text style={styles.fieldTitle}>Blockchain Transaction</Text>
            <Link style={`${styles.fieldValue} ${styles.simple}`}
              src={props.blockchainLink}>
              {props.blockchainLink}
            </Link>
          </View>
        </View>
      </div>
    </Document>
  );
};
