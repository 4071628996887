import { useEffect, useState } from 'react';

export const useIsMobileDevice = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handler = () => {
    setIsMobile(window.innerWidth <= 1280);
  };

  useEffect(() => {
    window.addEventListener('resize', handler);
    handler();

    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('load', handler);
    };
  }, []);

  return isMobile;
};
