import React, { lazy, Suspense } from "react";

const LazyPricingPackagePage = lazy(() => import("./PricingPackagePage"));

const PricingPackagePage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyPricingPackagePage {...props} />
  </Suspense>
);

export default PricingPackagePage;
