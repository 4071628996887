import { FileModel } from "../models/files/file.model";
import RestApi from "../utils/RestApi";
import { converterFileListToFormData } from "../utils/api-utils";

export class FileApi {
  static async upload(files: FileList | File[]): Promise<FileModel[]> {
    const formDataArr = converterFileListToFormData(files);


    const response = await Promise.all(formDataArr.map(formData => {
      return RestApi.post("private/files/upload", formData);
    }));

    return response.map(el => el.data);
  }
}

