import React, { lazy, Suspense } from "react";

const LazyUserDetailsPage = lazy(() => import("./UserDetailsPage"));

const UserDetailsPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyUserDetailsPage {...props} />
  </Suspense>
);

export default UserDetailsPage;
