import { RequestedReducerData } from "../../store.utils";
import { PaginationDataModel } from "../../../utils/type-utils";
import { BalanceTransaction } from "../../../models/transactions/transaction.model";
import { createReducer } from "typesafe-actions";
import { BalanceActions } from "./balance.actions";

export class StateModel {
  transactionsList = new RequestedReducerData<PaginationDataModel<BalanceTransaction>>({ result: [], total: 0 });
}

const initialState = new StateModel();

export const reducer = createReducer<StateModel>(initialState)
  .handleAction(BalanceActions.GetMyTransactionsAsync.success, (state, action) => ({
    ...state,
    transactionsList: {
      ...state.transactionsList,
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(BalanceActions.GetMyTransactionsAsync.request, (state, action) => ({
    ...state,
    transactionsList: {
      ...state.transactionsList,
      loading: true,
    },
  }))
  .handleAction(BalanceActions.GetUserTransactionsAsync.success, (state, action) => ({
    ...state,
    transactionsList: {
      ...state.transactionsList,
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(BalanceActions.GetUserTransactionsAsync.request, (state, action) => ({
    ...state,
    transactionsList: {
      ...state.transactionsList,
      loading: true,
    },
  }))
;
