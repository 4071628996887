import { FileModel } from "../files/file.model";

export enum WorkStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  PROTECTED = "PROTECTED",
  REGISTER = "register",
  NEED_INFO = "need info",
}

export interface WorkModel {
  id: string,
  ownerId: string,
  name: string,
  authorFullName: string,
  dateOfCreation: string | Date;
  fileId: string,
  etheriumAddress: string,
  status: WorkStatusEnum,
  createdDate: string | Date;
  coAuthors: {
    id: string,
    workId?: string | null,
    name: string
  }[],
  file: FileModel;
}


export class CreateWorkDto {
  authorFullName: string;
  coAuthors: { name: string }[];
  comment: string;
  dateOfCreation: string;
  fileId: string;
  name: string;

  constructor(private defaultData?: Partial<CreateWorkDto>) {
    this.authorFullName = defaultData ? (defaultData.authorFullName || "") : "";
    this.coAuthors = defaultData ? (defaultData.coAuthors || []) : [];
    this.comment = defaultData ? (defaultData.comment || "") : "";
    this.dateOfCreation = defaultData ? (defaultData.dateOfCreation || "") : "";
    this.fileId = defaultData ? (defaultData.fileId || "") : "";
    this.name = defaultData ? (defaultData.name || "") : "";
  }

}

export interface WorkDetailsFormModel extends Omit<CreateWorkDto, "fileId"> {
  coAuthors: { name: string, id: string }[],
  status: WorkStatusEnum;
  id: string;
  file: File | null
}


export interface VerifiedWorkUserNotOwner {
  isOwner: false;
  work: { name: string };
}

export interface VerifiedWorkUserOwner {
  isOwner: true;
  work: WorkModel;
}


export type VerifiedWorkResponse = VerifiedWorkUserOwner | VerifiedWorkUserNotOwner;
