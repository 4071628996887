import React, { lazy, Suspense } from "react";

const LazyRegisterYourWorkPage = lazy(() => import("./RegisterYourWorkPage"));

const RegisterYourWorkPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => {
  return (
    <Suspense fallback={null}>
      <LazyRegisterYourWorkPage {...props} />
    </Suspense>
  );
};

export default RegisterYourWorkPage;
