export const converterFileListToFormData = (files: FileList | File[]): FormData[] => {
  const fileArray: File[] = files instanceof Array ? files : [];

  if (files instanceof FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        fileArray.push(file);
      }
    }
  }


  return fileArray.map(file => {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
  });
};
