import React, { lazy, Suspense } from "react";

const LazyAppConfigPage = lazy(() => import("./AppConfigPage"));

const AppConfigPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyAppConfigPage {...props} />
  </Suspense>
);

export default AppConfigPage;
