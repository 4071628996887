import React, { useMemo, useState, useCallback } from 'react';
import styles from './Header.module.scss';
import { HeaderNavigation } from './components/HeaderNavigation';
// import Logo from "../../styles/image/copyright.svg";
import { Avatar, IconButton, Input, Button } from '@material-ui/core';
import BurgerIcon from '../../styles/image/burgerIcon.svg';
import { useDispatch } from 'react-redux';
import { SidenavActions } from '../../store/reducers/sidenav/sidenav.actions';
import { useTypedSelector } from '../../store/store.utils';
import classNames from 'classnames';
import { Logo } from '../../shared/Logo/Logo';
import SharedModal from '../../shared/SharedModal/SharedModal';
import { UserActions } from '../../store/reducers/user/user.actions';
import { UserModel } from '../../models/user/user.models';

const inline = {
  confirmName: {
    margin: 8,
  },
};

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const userInfo = useTypedSelector(state => state.user.userInfo.data);
  const openMobileSidenav = useTypedSelector(state => state.sidenav.open);

  const openMobileMenu = () => {
    dispatch(SidenavActions.toggle());
  };

  const shouldProvideName = useMemo<boolean>(() => {
    if (!userInfo) {
      return false;
    }
    if (userInfo.name) {
      return false;
    }
    return true;
  }, [userInfo]);

  return (
    <div className={classNames(styles.Header, { [styles.OpenSidenavMobile]: openMobileSidenav })}>
      <div className={styles.HeaderBurgerWrap}>
        <IconButton
          onClick={openMobileMenu}
          classes={{
            root: styles.HeaderBurgerButtonRoot,
          }}
          aria-label="burger-button">
          <img src={BurgerIcon} alt="burger-icon" />
        </IconButton>
      </div>
      <Logo color="#2D3959" />
      <div>
        <HeaderNavigation />
      </div>
      {userInfo && (
        <>
          <div className={styles.MobileProfileAvatarWrap}>
            <Avatar src={userInfo.avatarUrl} />
          </div>
          <SharedModal open={shouldProvideName} title="Complete profile setup">
            <SetupName user={userInfo} />
          </SharedModal>
        </>
      )}
    </div>
  );
};

const SetupName: React.FC<{ user: UserModel }> = ({ user }) => {
  const dispatch = useDispatch();
  const [legalName, setLegalName] = useState(user.name);
  const loading = useTypedSelector(state => state.user.userInfo.loading);

  const confirmHandler = useCallback(() => {
    dispatch(
      UserActions.UpdateOwnUserAsync.request({
        name: legalName,
      }),
    );
  }, [dispatch, legalName]);

  return (
    <>
      <p>Please, provide your legal name.</p>
      <Input
        placeholder="Legal Name"
        value={legalName}
        onChange={e => setLegalName(e.currentTarget.value)}
      />
      <Button
        color="primary"
        variant="contained"
        style={inline.confirmName}
        disabled={!legalName || loading}
        onClick={confirmHandler}>
        Save
      </Button>
    </>
  );
};

export default Header;
