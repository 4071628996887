import React, { useEffect, useState } from "react";
import styles from "../Sidenav.module.scss";
import PlusCircleIcon from "../../../shared/icons/PlusCircleIcon";
import ShieldIcon from "../../../shared/icons/ShieldIcon";
import ProfileUserIcon from "../../../shared/icons/ProfileUserIcon";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { RouteNames } from "../../../constants/RouteNames";
import { SvgIconInjectedProps } from "../../../shared/icons/type";
import { useUserRole } from "../../../store/reducers/user/user.reducer";
import { Roles } from "../../../models/user/user.models";
import ConfigIcon from "../../../shared/icons/ConfigIcon";
import DashboardIcon from "../../../shared/icons/DashboardIcon";
import PricingPackageMenuIcon from "../../../shared/icons/PricingPackageMenuIcon";

type NavigationLinkType = { name: string, link: string, icon: (props: SvgIconInjectedProps) => JSX.Element, alsoActiveBy?: (location: string) => boolean };

const navigationUserList: NavigationLinkType[] = [
  { name: "Dashboard", link: `/${RouteNames.ROOT}`, icon: PlusCircleIcon },
  { name: "Register your work", link: `/${RouteNames.REGISTER_YOUR_WORKS}`, icon: ShieldIcon },
  { name: "Verification", link: `/${RouteNames.VERIFICATION}`, icon: ProfileUserIcon },
  { name: "Profile", link: `/${RouteNames.PROFILE}`, icon: ProfileUserIcon },
];

const navigationAdminList: NavigationLinkType[] = [
  {
    name: "Dashboard", link: `/${RouteNames.ROOT}`, icon: DashboardIcon,
  },
  {
    name: "User management", link: `/${RouteNames.USER_MANAGEMENT}`, icon: PlusCircleIcon,
    alsoActiveBy: (location) => {
      return location.includes(RouteNames.USER);
    },
  },
  { name: "App Configurations", link: `/${RouteNames.APP_CONFIG}`, icon: ConfigIcon },
  { name: "Pricing Package", link: `/${RouteNames.PRICING_PACKAGE}`, icon: PricingPackageMenuIcon },
];

const NavigationItem = (props: NavigationLinkType & { active: boolean }) => (
  <li className={classNames({
    [styles.SidenavNavigationListActiveItem]: props.active,
  })}>
    <Link to={props.link}>
      {props.icon({ color: props.active ? "#776BF8" : undefined })}
      <span>{props.name}</span>
    </Link>
  </li>
);


const SidenavNavigationList = () => {
  const location = useLocation();
  const [navigationList, setNavigationList] = useState<NavigationLinkType[]>([]);
  const userRole = useUserRole();


  useEffect(() => {
    if (userRole) {
      switch (userRole) {
        case Roles.USER: {
          setNavigationList(navigationUserList);
          break;
        }
        case Roles.ADMIN: {
          setNavigationList(navigationAdminList);
          break;
        }
        default: {
          setNavigationList([]);
          break;
        }
      }
    }
  }, [userRole]);

  return (
    <div className={styles.SidenavNavigationList}>
      <ul>
        {
          navigationList.map((el, index) =>
            <React.Fragment key={index}>
              <NavigationItem {...el}
                              active={location.pathname === el.link || Boolean(el.alsoActiveBy && el.alsoActiveBy(location.pathname))}/>
            </React.Fragment>,
          )
        }
      </ul>
    </div>
  );
};

export default SidenavNavigationList;
