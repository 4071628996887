import { RequestedReducerData } from "../../store.utils";
import { PaginationDataModel } from "../../../utils/type-utils";
import { createReducer } from "typesafe-actions";
import { CreditPackageModel } from "../../../models/credit-package/credit-package.model";
import { CreditPackageActions } from "./creditPackage.actions";

export class StateModel {
  creditPackagesList = new RequestedReducerData<PaginationDataModel<CreditPackageModel>>({ result: [], total: 0 });
}

const initialState = new StateModel();

export const reducer = createReducer<StateModel>(initialState)
  .handleAction(CreditPackageActions.getCreditPackagesAsync.success, (state, action) => ({
    ...state,
    creditPackagesList: {
      ...state.creditPackagesList,
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(CreditPackageActions.getCreditPackagesAsync.request, (state, action) => ({
    ...state,
    creditPackagesList: {
      ...state.creditPackagesList,
      data: initialState.creditPackagesList.data,
      loading: true,
    },
  }));
