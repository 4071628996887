export enum RouteNames {
  ROOT = "",
  REGISTER_YOUR_WORKS = "register-your-works",
  VERIFICATION = "verification",
  PROFILE = "profile",
  LOGIN = "login",
  INFO = "info",
  NOT_FOUND = "not_found",
  APP = "app",
  HOME = "home",
  USER = "user",
  APP_CONFIG = "app-config",
  WELCOME = "welcome",
  VIEW_CERTIFICATE = "view-certificate",
  USER_MANAGEMENT = "user-management",
  PRICING_PACKAGE = "pricing-package"
}

export enum RouteParametersNames {
  ID = "id"
}
