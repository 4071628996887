import styles from "../Sidenav.module.scss";
import React from "react";
import { useTypedSelector } from "../../../store/store.utils";

const SidenavAvatar = () => {
  const userInfo = useTypedSelector(state => state.user.userInfo.data);
  return userInfo
    ? <div className={styles.SidenavAvatar}>
      <img src={userInfo.avatarUrl} alt='avatar'/>
    </div>
    : null;

};

export default SidenavAvatar;
