import RestApi from "../utils/RestApi";
import {
  UpdateUserDto,
  UserListItemModel,
  UserListRequestModel,
  UserModel,
  UserStatisticModel,
} from "../models/user/user.models";
import { PaginationDataModel } from "../utils/type-utils";
import { DEFAULT_SIZE } from "../constants/pagination.constants";
import { BalanceTransaction, BalanceTransactionTypes } from '../models/transactions/transaction.model';

export class UserApi {
  static async getUserStatistic() {
    // TODO refactor to return this data from backend
    const { data: { result } } = await RestApi.get<PaginationDataModel<BalanceTransaction>>(`private/balance-transactions`, {
      params: {
        page: 0,
        size: DEFAULT_SIZE,
        sort: "createdDate,asc",
      },
    });
    const hasUnusedTrialCredits = result.length === 1 && result[0].type === BalanceTransactionTypes.SIGN_UP;
    const { data } = await RestApi.get<UserStatisticModel>("private/users/my-stats");
    return {
      ...data,
      hasUnusedTrialCredits,
    }
  }

  static getUser() {
    return RestApi.get<UserModel>("private/users/profile-info");
  }

  static getUserList(params: UserListRequestModel) {
    if (!params.searchQuery) {
      delete params.searchQuery;
    }
    return RestApi.get<PaginationDataModel<UserListItemModel>>("private/users", {
      params: {
        ...params,
        page: params.page - 1,
        size: params.size || DEFAULT_SIZE,
      },
    });
  }

  static getUserById(userId: string) {
    return RestApi.get<UserListItemModel>("private/users/" + userId);
  }

  static updateUserOwnProfile(data: Partial<UpdateUserDto>) {
    return RestApi.patch<UserModel>("private/users", data);
  }

  static setUserBlockedStatus(userId: string, isBlocked: boolean) {
    return RestApi.patch<UserModel>(`private/users/${userId}`, undefined, {
      params: {
        isBlocked,
      },
    });
  }
}
