import React from "react";
import { SvgIconInjectedProps } from "./type";

const ProfileUserIcon = (props: SvgIconInjectedProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M10.0007 0C4.47809 0 0.000976562 4.47724 0.000976562 9.9999C0.000976562 15.5224 4.47809 20 10.0007 20C15.5234 20 20.0008 15.5224 20.0008 9.9999C20.0008 4.47711 15.5234 0 10.0007 0ZM10.0312 14.7177V14.7175H9.97015H5.69864C5.69864 11.5938 8.44213 11.5945 9.05094 10.7777L9.1206 10.4052C8.26526 9.97177 7.66146 8.92669 7.66146 7.70441C7.66146 6.09413 8.70893 4.78851 10.0007 4.78851C11.2926 4.78851 12.34 6.09413 12.34 7.70441C12.34 8.91629 11.747 9.95483 10.903 10.3952L10.9824 10.8184C11.6503 11.5956 14.3025 11.6463 14.3025 14.7177H10.0312Z"
        fill={props.color ? props.color : "#CBD0DD"}/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default ProfileUserIcon;
