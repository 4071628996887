import { applyMiddleware, createStore } from "redux";
import rootReducer, { rootEpic } from "./reducers/root-redux";
import { composeEnhancers } from "./utils";
import { createEpicMiddleware } from "redux-observable";

const epicMiddleware = createEpicMiddleware();

function configureStore() {

  return createStore(rootReducer, composeEnhancers(
    applyMiddleware(epicMiddleware),
  ));
}

const store = configureStore();

epicMiddleware.run(rootEpic);

export default store;
