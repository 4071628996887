import React, { useEffect, useState } from "react";
import styles from "./Sidenav.module.scss";
import SidenavAvatar from "./components/SidenavAvatar";
import SidenavUserName from "./components/SidenavUserName";
import SidenavNavigationList from "./components/SidenavNavigationList";
import { useTypedSelector } from "../../store/store.utils";
import classNames from "classnames";
import { Portal } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { SidenavActions } from "../../store/reducers/sidenav/sidenav.actions";

const MobileOverlay: React.FC<{ open: boolean }> = ({ open }) => {
  const [visible, setVisible] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      setVisible(true);
    } else {
      setTimeout(() => {
        setVisible(false);
      }, 200);
    }
  }, [open]);


  return visible ? (
    <Portal>
      <div onClick={() => {
        if (open) {
          dispatch(SidenavActions.toggle());
        }
      }} className={classNames(
        styles.SidenavOverlayForMobile,
        { [styles.SidenavOverlayForMobileVisible]: !open },
      )}/>
    </Portal>
  ) : null;
};

const Sidenav: React.FC = () => {
  const openMobileSidenav = useTypedSelector(state => state.sidenav.open);

  return (
    <>
      <div className={classNames(
        styles.Sidenav,
        { [styles.OpenSidenav]: openMobileSidenav },
      )}>
        <div className={styles.SidenavSimpleUserInfo}>
          <SidenavAvatar/>
          <SidenavUserName/>
        </div>
        <SidenavNavigationList/>
      </div>
      <MobileOverlay open={openMobileSidenav}/>
    </>
  );
};

export default Sidenav;
