import { isActionOf } from "typesafe-actions";
import { catchError, debounceTime, filter, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { UserListActions } from "./user-list.actions";
import { UserApi } from "../../../api/User.api";
import { EpicConfigured } from "../../store.utils";

export const fetchUserStatsFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(UserListActions.GetUserListAsync.request)),
    debounceTime(300),
    switchMap(action => {
      return from(UserApi.getUserList(action.payload)).pipe(
        map(response => UserListActions.GetUserListAsync.success(response.data)),
        catchError((error: Error) => of(UserListActions.GetUserListAsync.failure(error))),
      );
    }),
  );

export const fetchUserByIdFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(UserListActions.GetUserByIdAsync.request)),
    switchMap(action => {
      return from(UserApi.getUserById(action.payload)).pipe(
        map(response => UserListActions.GetUserByIdAsync.success(response.data)),
        catchError((error: Error) => of(UserListActions.GetUserByIdAsync.failure(error))),
      );
    }),
  );


export const UserListEpic = [
  fetchUserStatsFlow,
  fetchUserByIdFlow,
];
