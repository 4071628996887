import { UserActions } from "./user.actions";
import { UserModel, UserStatisticModel } from "../../../models/user/user.models";
import { RequestedReducerData, useTypedSelector } from "../../store.utils";
import { createReducer } from "typesafe-actions";


export class StateModel {
  userInfo = new RequestedReducerData<UserModel | null>(null, Boolean(localStorage.getItem("accessToken")));
  userStat = new RequestedReducerData<UserStatisticModel>({
    credits: 0,
    inProgressFiles: 0,
    protectedFiles: 0,
    hasUnusedTrialCredits: false,
  });
}

export const reducer = createReducer<StateModel>(new StateModel())
  .handleAction(UserActions.GetUserStatAsync.request, state => ({
    ...state,
    userStat: {
      ...state.userStat,
      loading: true,
    },
  }))
  .handleAction(UserActions.GetUserStatAsync.success as any, (state, action) => ({
    ...state,
    userStat: {
      ...state.userStat,
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(UserActions.GetUserStatAsync.failure as any, state => ({
    ...state,
    userStat: {
      ...state.userStat,
      loading: false,
    },
  }))

  .handleAction(UserActions.GetUserAsync.request as any, (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      loading: true,
    },
  }))
  .handleAction(UserActions.GetUserAsync.success as any, (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(UserActions.GetUserAsync.failure as any, (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      loading: false,
    },
  }))
  .handleAction(UserActions.UpdateOwnUserAsync.request as any, (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      loading: true,
    },
  }))
  .handleAction(UserActions.UpdateOwnUserAsync.success as any, (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(UserActions.UpdateOwnUserAsync.failure as any, (state, action) => ({
    ...state,
    userInfo: {
      ...state.userInfo,
      loading: false,
    },
  }));


export const useUserRole = () => useTypedSelector(({ user: { userInfo: { data } } }) => data ? data.role : null);
