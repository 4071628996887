import RestApi from "../utils/RestApi";
import { PaginationDataModel, PaginationParamModel } from "../utils/type-utils";
import { BalanceTransaction } from "../models/transactions/transaction.model";
import { DEFAULT_SIZE } from "../constants/pagination.constants";

export class BalanceApi {
  static setUserBalance(userId: string, balance: number) {
    return RestApi.post(`private/balance-transactions/set-balance-for-user/${userId}`, {
      balance,
    });
  }

  static getMyTransactions(params: PaginationParamModel) {
    return RestApi.get<PaginationDataModel<BalanceTransaction>>(`private/balance-transactions`, {
      params: {
        ...params,
        page: params.page - 1,
        size: params.size || DEFAULT_SIZE,
        sort: "createdDate,desc",
      },
    });
  }

  static getUserTransactions(params: PaginationParamModel, userId: string) {
    return RestApi.get<PaginationDataModel<BalanceTransaction>>(`private/balance-transactions/user/${userId}`, {
      params: {
        ...params,
        page: params.page - 1,
        size: params.size || DEFAULT_SIZE,
        sort: "createdDate,desc",
      },
    });
  }

}
