import { UserListActions } from "./user-list.actions";
import { UserListItemModel } from "../../../models/user/user.models";
import { RequestedReducerData } from "../../store.utils";
import { createReducer } from "typesafe-actions";
import { Maybe, PaginationDataModel } from "../../../utils/type-utils";


export class StateModel {
  userList = new RequestedReducerData<PaginationDataModel<UserListItemModel>>({ result: [], total: 0 });
  activeUser = new RequestedReducerData<Maybe<UserListItemModel>>(null);
}

export const reducer = createReducer<StateModel>(new StateModel())
  .handleAction(UserListActions.GetUserListAsync.success, (state, action) => ({
    ...state,
    userList: {
      ...state.userList,
      data: action.payload,
      loading: false,
    },
  }))

  .handleAction(UserListActions.GetUserByIdAsync.success, (state, action) => ({
    ...state,
    activeUser: {
      ...state.activeUser,
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction(UserListActions.GetUserByIdAsync.request, (state, action) => ({
    ...state,
    activeUser: {
      ...state.activeUser,
      loading: true,
    },
  }));

