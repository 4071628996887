import { createMuiTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export const uiBreakpoints = {
  ...breakpoints,
  whenSmall: breakpoints.down(1280),
  whenWide: breakpoints.up(1280),
};

(window as any).br = uiBreakpoints;

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2D3959',
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: '#5F6D95',
      },
      h6: {
        fontFamily: 'Proxima Nova, sans-serif',
        fontWeight: 600,
        fontSize: '16px',
        color: '#2D3959',
      },
    },
    MuiButton: {
      root: {
        minWidth: '110px',
        minHeight: '40x',
        borderRadius: '10px',
      },
      label: {
        textTransform: 'capitalize',
        fontFamily: 'Proxima Nova, sans-serif',
        fontSize: '14px',
      },
      outlinedSecondary: {
        color: 'rgba(95,109,149,0.45)',
        borderColor: 'rgba(95,109,149,0.46)',
        backgroundColor: '#FFF',
        '&:hover': {
          color: '#5F6D95',
          borderColor: '#5F6D95',
          backgroundColor: '#FFF',
        },
      },
      outlinedPrimary: {
        borderColor: 'rgba(106,92,251,0.57)',
        color: 'rgba(106,92,251,0.56)',
        '&:hover': {
          borderColor: '#6A5CFB',
          color: '#6A5CFB',
          backgroundColor: '#FFF',
        },
      },
      containedPrimary: {
        backgroundColor: '#6A5CFB',
        boxShadow: '40px 20px 60px rgba(101, 86, 252, 0.4)',
        color: '#F2F2F2',
        '&:hover': {
          backgroundColor: '#4d43b6',
          boxShadow: '40px 20px 60px rgba(101, 86, 252, 0.4)',
        },
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: 'auto',
      },
    },
    MuiDialog: {
      paper: {
        position: 'relative',
        background: '#FFFFFF',
        borderRadius: '20px',
        padding: '40px 60px 44px',
        [uiBreakpoints.whenSmall]: {
          padding: '56px 0px 24px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '30px',
      },
    },
    MuiDialogTitle: {
      root: {
        color: '#2D3959',
        padding: '0 20px 30px 20px',
        textAlign: 'center',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #5F6D95',
          opacity: 0.1,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '10px',
        backgroundColor: 'rgba(95, 109, 149, 0.3)',
        color: '#5F6D95',
        opacity: 0.3,
        '&:hover': {
          opacity: 1,
          backgroundColor: 'rgba(95, 109, 149, 0.3)',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        width: '15px',
        height: '15px',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Proxima Nova, sans-serif',
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Proxima Nova, sans-serif',
        color: '#5F6D95',
        fontSize: '12px',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiLinearProgress: {
      root: {
        height: '9px',
        borderRadius: '200px',
      },
      colorPrimary: {
        backgroundColor: '#F9F8FE',
      },
      barColorPrimary: {
        backgroundColor: '#776BF8',
        boxShadow: '40px 20px 60px rgba(101, 86, 252, 0.4)',
        borderRadius: '30px',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.1)',
        borderRadius: 15,
      },
      list: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiMenuItem: {
      root: {
        color: '#7b7f88',
        fontSize: 12,
      },
    },
    // @ts-ignore
    MuiPaginationItem: {
      icon: {
        color: '#4A39FF',
      },
      textPrimary: {
        color: '#ABB4C6',
        '&.Mui-selected': {
          backgroundColor: 'rgba(74,57,255,0.1)',
          color: '#4A39FF',
          '&:hover': {
            backgroundColor: 'rgba(74,57,255,0.5) !important',
          },
        },
      },
    },
    MuiAlert: {
      icon: {
        color: '#fff !important',
        padding: '11px 0',
      },
      standardError: {
        backgroundColor: '#f44336',
        color: '#fff',
      },
    },
    MuiExpansionPanel: {
      rounded: {
        boxShadow: 'none',
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        marginBottom: 10,
        padding: '16px 18px 15px 19px',
        flex: 1,
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 'initial',
        display: 'flex',
        '&.Mui-expanded': {
          minHeight: 'initial',
        },
      },
      content: {
        margin: 0,
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '34px 75px',
      },
    },
  },
});
