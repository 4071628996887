import { isActionOf } from "typesafe-actions";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { UserActions } from "./user.actions";
import { UserApi } from "../../../api/User.api";
import { EpicConfigured } from "../../store.utils";

export const fetchUserStatsFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(UserActions.GetUserStatAsync.request)),
    switchMap(action => {
      return from(UserApi.getUserStatistic()).pipe(
        map(data => UserActions.GetUserStatAsync.success(data)),
        catchError((error: Error) => of(UserActions.GetUserStatAsync.failure(error))),
      );
    }),
  );


export const fetchUserFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(UserActions.GetUserAsync.request)),
    switchMap(action => {
      return from(UserApi.getUser()).pipe(
        map(response => UserActions.GetUserAsync.success({
          ...response.data,
          // role: Roles.ADMIN
        })),
        catchError((error: Error) => of(UserActions.GetUserAsync.failure(error))),
      );
    }),
  );

export const updateOwnUserFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(UserActions.UpdateOwnUserAsync.request)),
    switchMap(action => {
      return from(UserApi.updateUserOwnProfile(action.payload)).pipe(
        map(response => UserActions.UpdateOwnUserAsync.success({
          ...response.data,
        })),
        catchError((error: Error) => of(UserActions.UpdateOwnUserAsync.failure(error))),
      );
    }),
  );


export const UserEpic = [
  fetchUserStatsFlow,
  fetchUserFlow,
  updateOwnUserFlow,
];
