import { createReducer } from "typesafe-actions";
import { SidenavActions } from "./sidenav.actions";


export class StateModel {
  open = false;
}

export const reducer = createReducer<StateModel>(new StateModel())
  .handleAction(SidenavActions.toggle, state => ({
    ...state,
    open: !state.open,
  }));
