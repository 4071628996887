import { isActionOf } from "typesafe-actions";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { WorksActions } from "./works.actions";
import { WorksApi } from "../../../api/Works.api";
import { EpicConfigured } from "../../store.utils";

export const fetchWorkListFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(WorksActions.GetWorkListAsync.request)),
    switchMap(action => {
      return from(WorksApi.getWorkList(action.payload)).pipe(
        map(response => WorksActions.GetWorkListAsync.success(response.data)),
        catchError((error: Error) => of(WorksActions.GetWorkListAsync.failure(error))),
      );
    }),
  );

export const fetchWorkListByUserIdFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(WorksActions.GetWorkListByUserIdAsync.request)),
    switchMap(action => {
      return from(WorksApi.getWorkListByUserId(action.payload)).pipe(
        map(response => WorksActions.GetWorkListByUserIdAsync.success(response.data)),
        catchError((error: Error) => of(WorksActions.GetWorkListByUserIdAsync.failure(error))),
      );
    }),
  );

export const WorksEpic = [
  fetchWorkListFlow,
  fetchWorkListByUserIdFlow,
];
