import { PaginationParamModel } from "../../utils/type-utils";

export enum Roles {
  ADMIN = "ADMIN",
  USER = "USER"
}

export enum UserStatusEnum {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
}

export type UsersStatusFilterType = UserStatusEnum | "ALL"

export interface UserListRequestModel extends PaginationParamModel {
  status: UsersStatusFilterType,
  searchQuery?: string
}

export interface UserModel {
  id: string;
  auth_sub: string
  created_at: Date;
  role: Roles;
  avatarUrl: string;
  name: string;
  email: string;
  status: UserStatusEnum;
  birthday: string | null;
  isBlocked: boolean;
}


export interface UserStatisticModel {
  credits: number;
  inProgressFiles: number;
  protectedFiles: number;
  // TODO this is virtual field generated by frontend, backend does not return this field yet. Refactor when backend ready.
  hasUnusedTrialCredits: boolean;
}

export interface UpdateUserDto {
  birthday: string,
  country: string,
  inProgressFiles: number,
  language: string,
  name: string,
}


export type UserListItemModel = UserStatisticModel & UserModel
