import { createReducer } from "typesafe-actions";
import { ActionNames } from "./snackbar.actions";


export class StateModel {
  open = false;
  message = "";
}

export const reducer = createReducer<StateModel>(new StateModel())
  .handleType(ActionNames.OPEN, (state, actions) => ({
    ...state,
    open: true,
    message: actions.payload.message || "",
  }))
  .handleType(ActionNames.CLOSE, state => ({
    ...state,
    open: false,
  }));
