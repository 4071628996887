import React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { RouteNames, RouteParametersNames } from "./constants/RouteNames";
import DashboardPage from "./pages/user/DashboardPage/DashboardPage.lazy";
import AdminDashboardPage from "./pages/admin/DashboardPage/DashboardPage.lazy";
import RegisterYourWorkPage from "./pages/user/RegisterYourWork/RegisterYourWorkPage.lazy";
import VerificationPage from "./pages/user/VerificationPage/VerificationPage.lazy";
import ProfilePage from "./pages/user/ProfilePage/ProfilePage.lazy";
import { roleAccessRoute } from "./hoc/roleAccessRoute";
import { Roles } from "./models/user/user.models";
import UserListPage from "./pages/admin/UserListPage/UserListPage.lazy";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage.lazy";
import UserDetailsPage from "./pages/admin/UserDetailsPage/UserDetailsPage.lazy";
import AppConfigPage from "./pages/admin/AppConfigPage/AppConfigPage.lazy";
import PricingPackagePage from "./pages/admin/PricingPackagePage/PricingPackagePage.lazy";

const routes: RouteProps[] = [
  {
    component: NotFoundPage, path: `/${RouteNames.NOT_FOUND}`, exact: true,
  },
  {
    component: roleAccessRoute({
      [Roles.USER]: DashboardPage,
      [Roles.ADMIN]: AdminDashboardPage,
    }), path: `/${RouteNames.ROOT}`, exact: true,
  },
  {
    component: roleAccessRoute({
      [Roles.ADMIN]: UserListPage,
    }), path: `/${RouteNames.USER_MANAGEMENT}`, exact: true,
  },
  {
    component: roleAccessRoute({
      [Roles.USER]: ProfilePage,
    }), path: `/${RouteNames.PROFILE}`, exact: true,
  },
  {
    component: roleAccessRoute({
      [Roles.ADMIN]: UserDetailsPage,
    }),
    path: `/${RouteNames.USER}/:${RouteParametersNames.ID}`,
    exact: true,
  },
  {
    component: roleAccessRoute({
      [Roles.ADMIN]: AppConfigPage,
    }),
    path: `/${RouteNames.APP_CONFIG}`,
    exact: true,
  },
  {
    component: roleAccessRoute({
      [Roles.USER]: RegisterYourWorkPage,
    }),
    path: `/${RouteNames.REGISTER_YOUR_WORKS}`,
    exact: true,
  },
  {
    component: roleAccessRoute({
      [Roles.USER]: VerificationPage,
    }), path: `/${RouteNames.VERIFICATION}`, exact: true,
  },
  {
    component: roleAccessRoute({
      [Roles.ADMIN]: PricingPackagePage,
    }), path: `/${RouteNames.PRICING_PACKAGE}`, exact: true,
  },

];


export const RootRouting = () => {
  return (
    <Switch>
      {
        routes.map((route, index) => <Route key={index} {...route}/>)
      }
      <Redirect from='*' to={`/${RouteNames.NOT_FOUND}`}/>
    </Switch>
  );
};
