import RestApi from "../utils/RestApi";
import { Maybe, PaginationDataModel, PaginationParamModel } from "../utils/type-utils";
import {
  CreateWorkDto,
  VerifiedWorkResponse,
  WorkDetailsFormModel,
  WorkModel,
  WorkStatusEnum,
} from "../models/works/works.models";
import { DEFAULT_SIZE } from "../constants/pagination.constants";
import { FileApi } from "./File.api";
import { converterFileListToFormData } from "../utils/api-utils";

export class WorksApi {
  static getCertificate(workId: string) {
    return RestApi.get<Blob>(`public/works/certificate/${workId}`, {
      responseType: 'blob',
    });
  }

  static getOneWork(workId: string) {
    return RestApi.get<WorkModel>(`public/works/${workId}`);
  }

  static getWorkList(params: PaginationParamModel & { status: Maybe<WorkStatusEnum> }) {
    return RestApi.get<PaginationDataModel<WorkModel>>("private/works", {
      params: {
        ...params,
        page: params.page - 1,
        size: params.size || DEFAULT_SIZE,
        sort: 'createdDate,desc',
      },
    });
  }

  static getWorkListByUserId({ userId, ...params }: PaginationParamModel & { status: Maybe<WorkStatusEnum>, userId: string }) {
    return RestApi.get<PaginationDataModel<WorkModel>>(`private/works/user/${userId}`, {
      params: {
        ...params,
        page: params.page - 1,
        size: params.size || DEFAULT_SIZE,
      },
    });
  }

  static async createWork(body: WorkDetailsFormModel[]) {
    const uploadedFileIds = await FileApi.upload(body.map(el => el.file) as File[]);
    const works: CreateWorkDto[] = body.map((el, index) => {
      const data = { ...el };
      delete data.file;
      return {
        ...data,
        fileId: uploadedFileIds[index].id,
      };
    });
    return RestApi.post<WorkModel>("private/works", works);
  }


  static async verifyWorkList(files: FileList | File[]): Promise<VerifiedWorkResponse[]> {
    const formDataArr = converterFileListToFormData(files);


    const response = await Promise.all(formDataArr.map(formData => {
      return RestApi.post<VerifiedWorkResponse>("private/works/verify", formData);
    }));

    return response.map(el => el.data);
  }
}
