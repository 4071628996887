import React, { useMemo } from "react";
import moment from "moment";
import { HTMLCopyrightCertificate } from './HTMLCopyrightCertificate/CopyrightCertificate';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { WorksApi } from '../../api/Works.api';
import { WorkStatusEnum } from '../../models/works/works.models';

export const CertificatePage: React.FC = () => {
  const { certificateId } = useParams<{ certificateId: string }>();
  const { loading, result, error } = useAsync(async (id: string) => WorksApi.getOneWork(id), [certificateId]);

  const details = useMemo(() => {
    if (!result) {
      return;
    }

    const data = result.data;

    const certificateProps = {
      ...data,
      name: data.name || "",
      registrationDate: moment(data.createdDate).format("MMMM DD YYYY, h:mm:ss A"),
      creationDate: moment(data.dateOfCreation).format("MMMM DD YYYY, h:mm:ss A"),
      author: data.authorFullName || "",
      coAuthors: data.coAuthors?.map(item => item.name) || [],
      fileName: data.file?.name || "song.mp3",
      blockchainLink: data.etheriumAddress ? (process.env.REACT_APP_BLOCKCHAIN_ROOT_URL + data.etheriumAddress) : "#",
      fileHash: data.file?.fingerprint || "",
    };
    return certificateProps;
  }, [result]);

  if (!certificateId) {
    return (
      <div>invalid certificate</div>
    );
  }

  if (loading) {
    return (
      <div>loading...</div>
    );
  }

  if (error) {
    return (
      <div>{JSON.stringify(error)}</div>
    );
  }

  if (!details) {
    return null;
  }

  if (details.status !== WorkStatusEnum.PROTECTED) {
    return (
      <div>
        Certificate generation in process. Go to website to view and download it.
      </div>
    );
  }

  return (
    <HTMLCopyrightCertificate {...details} />
  );

};
