import { WorksActions } from "./works.actions";
import { RequestedReducerData } from "../../store.utils";
import { WorkModel } from "../../../models/works/works.models";
import { PaginationDataModel } from "../../../utils/type-utils";
import { createReducer } from "typesafe-actions";


export class StateModel {
  workList = new RequestedReducerData<PaginationDataModel<WorkModel>>({
    total: 0,
    result: [],
  });
}

export const reducer = createReducer<StateModel>(new StateModel())
  .handleAction([WorksActions.GetWorkListAsync.request, WorksActions.GetWorkListAsync.failure], (state, action) => ({
    ...state,
    workList: {
      ...state.workList,
      loading: true,
    },
  }))
  .handleAction(WorksActions.GetWorkListAsync.success, (state, action) => ({
    ...state,
    workList: {
      ...state.workList,
      data: action.payload,
      loading: false,
    },
  }))
  .handleAction([WorksActions.GetWorkListByUserIdAsync.request, WorksActions.GetWorkListByUserIdAsync.failure], (state, action) => ({
    ...state,
    workList: {
      ...state.workList,
      loading: true,
    },
  }))
  .handleAction(WorksActions.GetWorkListByUserIdAsync.failure, (state, action) => ({
    ...state,
    workList: {
      ...state.workList,
      loading: false,
    },
  }))
  .handleAction(WorksActions.GetWorkListByUserIdAsync.success, (state, action) => ({
    ...state,
    workList: {
      ...state.workList,
      data: action.payload,
      loading: false,
    },
  }));
