import { EpicConfigured } from "../../store.utils";
import { BalanceActions } from "./balance.actions";
import { isActionOf } from "typesafe-actions";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { BalanceApi } from "../../../api/Balance.api";

export const fetchMyTransactionsFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(BalanceActions.GetMyTransactionsAsync.request)),
    mergeMap(action => from(BalanceApi.getMyTransactions(action.payload)).pipe(
      map(response => BalanceActions.GetMyTransactionsAsync.success(response.data)),
      catchError((error: Error) => of(BalanceActions.GetMyTransactionsAsync.failure(error))),
    )),
  );

export const fetchUserTransactionsFlow: EpicConfigured = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(BalanceActions.GetUserTransactionsAsync.request)),
    mergeMap(({ payload: { userId, ...params } }) => from(BalanceApi.getUserTransactions(params, userId)).pipe(
      map(response => BalanceActions.GetUserTransactionsAsync.success(response.data)),
      catchError((error: Error) => of(BalanceActions.GetUserTransactionsAsync.failure(error))),
    )),
  );

export const BalanceEpic = [
  fetchMyTransactionsFlow,
  fetchUserTransactionsFlow,
];
