import React, { useEffect, useState, useMemo } from 'react';
import styles from './MainLayout.module.scss';
import classNames from 'classnames';
import Header from '../../components/Header/Header';
import Sidenav from '../../components/Sidenav/Sidenav';
import { useLocation, Link } from 'react-router-dom';
import { RouteNames } from '../../constants/RouteNames';
import { useIsMobileDevice } from '../../hooks/is-mobile-device.hook';
import { useTypedSelector } from '../../store/store.utils';

const inline = {
  trialBanner: {
    marginBottom: 32,
    padding: 16,
    background: '#d0d2e6',
    color: '#59577b',
    borderRadius: 32,
  },
  bannerHeader: {
    marginBottom: 16,
  },
  link: {
    color: 'blue',
  },
};

const MainLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const [withSidenav, setWithSidenav] = useState(false);
  const isMobile = useIsMobileDevice();
  const userStat = useTypedSelector(state => state.user.userStat);

  useEffect(() => {
    switch (true) {
      case location.pathname.includes(RouteNames.INFO):
      case location.pathname.includes(RouteNames.LOGIN): {
        setWithSidenav(false);
        break;
      }
      default: {
        setWithSidenav(true);
        break;
      }
    }
  }, [location.pathname]);

  const freeTrialBanner = useMemo(() => {
    if (!userStat.data.hasUnusedTrialCredits) {
      return;
    }
    return (
      <div style={inline.trialBanner}>
        <h3 style={inline.bannerHeader}>Alright! you've got a free Credit!</h3>
        <p>
          Go to{' '}
          <Link style={inline.link} to={RouteNames.REGISTER_YOUR_WORKS}>
            Register your work
          </Link>{' '}
          page and create your first ever copyright on the blockchain.
        </p>
      </div>
    );
  }, [userStat]);

  return (
    <div className={styles.MainLayout}>
      <Header />
      <div className={styles.SidenavContainer}>
        {(withSidenav || isMobile) && <Sidenav />}
        <div className={classNames(styles.Container, { [styles.SindenavOff]: !withSidenav })}>
          <div className={styles.Raw}>
            <div className={styles.Content}>
              {freeTrialBanner}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
