import styles from '../Sidenav.module.scss';
import React, { useMemo } from 'react';
import { useTypedSelector } from '../../../store/store.utils';
import { RouteNames } from '../../../constants/RouteNames';
import { Link } from 'react-router-dom';

const inline = {
  link: {
    // TODO use theme color
    color: '#776BF8',
  },
};

const SidenavUserName = () => {
  const userInfo = useTypedSelector(state => state.user.userInfo.data);

  const nameContainer = useMemo(() => {
    if (!userInfo) {
      return;
    }
    if (!userInfo.name) {
      return (
        <Link style={inline.link} to={`${RouteNames.PROFILE}`}>
          setup name in profile
        </Link>
      );
    }
    return <h4>{userInfo.name}</h4>;
  }, [userInfo]);

  return (
    <div className={styles.SidenavUserName}>
      {nameContainer}
      <p className={styles.SidenavUserNameShortDesc}>
        <b>Copyright your music</b> On The Blockchain
      </p>
    </div>
  );
};

export default SidenavUserName;
