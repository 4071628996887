import React from "react";
import { SvgIconInjectedProps } from "./type";

const ShieldIcon = (props: SvgIconInjectedProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M10.0002 6.18945C7.89889 6.18945 6.18945 7.89889 6.18945 10C6.18945 12.1013 7.89889 13.8108 10.0002 13.8108C12.1013 13.8108 13.8108 12.1013 13.8108 10C13.8108 7.89889 12.1013 6.18945 10.0002 6.18945ZM12.2626 9.28241L9.53265 12.0124C9.41806 12.127 9.26776 12.1843 9.11761 12.1843C8.96747 12.1843 8.81732 12.1271 8.70273 12.0124L7.65948 10.9691C7.4303 10.7401 7.4303 10.3685 7.65948 10.1393C7.88852 9.91016 8.26007 9.91016 8.48926 10.1393L9.11761 10.7677L11.4328 8.45264C11.6619 8.22345 12.0334 8.22345 12.2626 8.45264C12.4918 8.68167 12.4918 9.05322 12.2626 9.28241Z"
        fill={props.color ? props.color : "#CBD0DD"}/>
      <path
        d="M18.4956 5.42651L18.495 5.41094C18.4863 5.21883 18.4805 5.01559 18.477 4.78945C18.4606 3.6867 17.584 2.77454 16.4811 2.71289C14.1816 2.58456 12.4028 1.83475 10.8828 0.353278L10.8699 0.340918C10.3741 -0.11364 9.62628 -0.11364 9.13037 0.340918L9.1174 0.353278C7.59748 1.83475 5.81862 2.58456 3.51912 2.71304C2.41637 2.77454 1.5396 3.6867 1.52328 4.78961C1.51992 5.01421 1.51397 5.21746 1.50527 5.41094L1.50436 5.4471C1.45965 7.79192 1.40411 10.7102 2.38036 13.3589C2.91716 14.8155 3.73015 16.0817 4.79658 17.1225C6.01118 18.3078 7.60206 19.2488 9.52497 19.9193C9.58753 19.9411 9.65253 19.9588 9.71875 19.9721C9.81213 19.9907 9.90613 20 10.0001 20C10.0941 20 10.1883 19.9907 10.2815 19.9721C10.3477 19.9588 10.4132 19.9409 10.476 19.919C12.3967 19.2473 13.9859 18.3058 15.1994 17.1207C16.2654 16.0796 17.0784 14.8131 17.6156 13.3562C18.5955 10.6995 18.5401 7.77574 18.4956 5.42651ZM9.99982 14.9846C7.25141 14.9846 5.01555 12.7487 5.01555 10.0003C5.01555 7.25191 7.25141 5.01605 9.99982 5.01605C12.7481 5.01605 14.9841 7.25191 14.9841 10.0003C14.9841 12.7487 12.7481 14.9846 9.99982 14.9846Z"
        fill={props.color ? props.color : "#CBD0DD"}/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>


);

export default ShieldIcon;
