import { EpicConfigured } from "../../store.utils";
import { isActionOf } from "typesafe-actions";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { CreditPackageActions } from "./creditPackage.actions";
import { from, of } from "rxjs";
import { CreditPackageApi } from "../../../api/CreditPackage.api";

export const fetchCreditPackagesFlow: EpicConfigured =
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(CreditPackageActions.getCreditPackagesAsync.request)),
      mergeMap(action => from(CreditPackageApi.getCreditPackages()).pipe(
        map(response => CreditPackageActions.getCreditPackagesAsync.success(response.data)),
        catchError(err => of(CreditPackageActions.getCreditPackagesAsync.failure(err))),
      )),
    );

export const CreditPackageEpic = [
  fetchCreditPackagesFlow,
];
