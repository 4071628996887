import { combineReducers } from "redux";
import * as fromSidenav from "./sidenav/sidenav.reducer";
import * as fromUser from "./user/user.reducer";
import * as fromUserList from "./userList/user-list.reducer";
import * as fromWorks from "./works/works.reducer";
import * as fromSnackbar from "./snackbar/snackbar.reducer";
import * as fromBalance from "./balance/balance.reducer";
import * as fromCreditPackage from "./creditPackage/creditPackage.reducer";
import { combineEpics } from "redux-observable";
import { UserEpic } from "./user/user.epic";
import { UserListEpic } from "./userList/user-list.epic";
import { WorksEpic } from "./works/works.epic";
import { BalanceEpic } from "./balance/balance.epic";
import { CreditPackageEpic } from "./creditPackage/creditPackage.epic";


export const rootEpic = combineEpics.apply({}, [
  ...UserEpic,
  ...WorksEpic,
  ...UserListEpic,
  ...BalanceEpic,
  ...CreditPackageEpic,
]);

const reducers = combineReducers({
  sidenav: fromSidenav.reducer,
  works: fromWorks.reducer,
  user: fromUser.reducer,
  userList: fromUserList.reducer,
  snackbar: fromSnackbar.reducer,
  balance: fromBalance.reducer,
  creditPackage: fromCreditPackage.reducer,
});

export default reducers;

