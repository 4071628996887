import React from "react";
import { SvgIconInjectedProps } from "./type";


const LogoIcon = (props: SvgIconInjectedProps) => (
    <svg width="140" height="38" viewBox="0 0 140 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path d="M52.5847 10.3831C50.0754 10.3831 47.9064 11.7179 46.924 13.8666L46.4366 14.933V6.25754C46.4366 4.87685 45.6457 4.38721 44.9689 4.38721H43.7422V20.0659C43.7422 25.4276 46.8479 28.8902 51.6542 28.8902C56.6171 28.8902 60.0831 24.9648 60.0831 19.3445C60.0831 13.1549 56.318 10.3831 52.5847 10.3831ZM55.9533 24.5936C54.9965 25.8322 53.6529 26.4872 52.0677 26.4872C48.4364 26.4872 46.4366 24.0731 46.4366 19.6892C46.4366 15.6257 48.6247 12.7879 51.7574 12.7879C54.3265 12.7879 57.3888 13.9853 57.3888 19.6892C57.3888 21.6815 56.8924 23.3772 55.9533 24.5936Z"
                  fill={props.color || 'white'}/>
            <path d="M69.5465 26.3157H67.7682C65.6193 26.3157 64.6182 25.2988 64.6182 23.1153V6.53196C64.6182 5.16884 63.9074 4.38721 62.6699 4.38721H61.9603V23.4923C61.9603 26.9876 63.7029 28.6865 67.2876 28.6865C69.2914 28.6865 69.5483 27.8335 69.5483 27.0899L69.5465 26.3157Z"
                  fill={props.color || 'white'}/>
            <path d="M76.3797 10.4175C71.2653 10.4175 68.0886 14.0764 68.0886 19.9632C68.0886 25.137 71.5755 28.892 76.3797 28.892C81.3224 28.892 84.3948 25.208 84.3948 19.2784C84.3948 13.9781 81.1738 10.4175 76.3797 10.4175ZM80.4544 24.5971C79.5614 25.8113 78.2597 26.4531 76.6903 26.4531C74.8503 26.4531 73.3304 25.7758 72.2917 24.4952C71.2815 23.2512 70.749 21.4587 70.749 19.3122C70.749 15.3903 72.7834 12.8553 75.9323 12.8553C79.7288 12.8553 81.7355 15.3243 81.7355 19.9959C81.7348 21.8673 81.2921 23.4578 80.4544 24.5971Z"
                  fill={props.color || 'white'}/>
            <path d="M96.813 25.2783C94.9791 23.3871 93.44 21.3708 91.762 19.1206L91.6386 18.9545L91.7772 18.8006C96.5777 13.449 98.2049 12.0568 99.8284 10.7615H98.1688C97.0504 10.7615 96.2426 11.0685 95.937 11.3713C94.2629 12.9396 91.4994 15.791 89.2023 18.6815L88.7953 19.1941L89.2019 19.7028C89.5675 20.1603 89.9354 20.6234 90.3055 21.0921C92.0965 23.3545 93.9494 25.6937 95.9381 27.7003C96.577 28.3686 97.243 28.6523 98.1688 28.6523C99.5282 28.6523 99.7638 28.2739 99.9586 27.5794C99.0751 27.3258 98.3351 26.8573 96.813 25.2783Z"
                  fill={props.color || 'white'}/>
            <path d="M88.0032 28.5495H88.7475V6.18903C88.7475 4.97657 88.1543 4.38721 86.9349 4.38721H86.2271V26.3362C86.2271 27.8668 86.7736 28.5495 88.0032 28.5495Z"
                  fill={props.color || 'white'}/>
            <path d="M107.525 10.4175C102.411 10.4175 99.2339 14.0764 99.2339 19.9632C99.2339 25.137 102.721 28.892 107.525 28.892C112.468 28.892 115.54 25.208 115.54 19.2784C115.54 13.9781 112.319 10.4175 107.525 10.4175ZM111.599 24.5971C110.706 25.8113 109.404 26.4531 107.835 26.4531C105.995 26.4531 104.475 25.7758 103.436 24.4952C102.426 23.2512 101.894 21.4587 101.894 19.3122C101.894 15.3903 103.928 12.8553 107.077 12.8553C110.874 12.8553 112.881 15.3243 112.881 19.9959C112.881 21.8673 112.438 23.4578 111.599 24.5971Z"
                  fill={props.color || 'white'}/>
            <path d="M126.22 10.3831C120.696 10.3831 117.653 13.8461 117.653 20.1343V32.2639C117.653 33.7429 118.185 34.3742 119.431 34.3742H120.313V24.85L120.797 25.8113C121.694 27.5963 123.83 28.892 125.875 28.892C130.67 28.892 133.891 25.0007 133.891 19.2092C133.891 13.7578 129.909 10.3831 126.22 10.3831ZM129.911 24.7962C129.045 25.9025 127.768 26.4872 126.22 26.4872C122.422 26.4872 120.244 23.8596 120.244 19.277C120.244 15.235 122.221 12.8202 125.531 12.8202C129.206 12.8202 131.231 15.3925 131.231 20.0637C131.231 22.0861 130.787 23.6774 129.911 24.7962Z"
                  fill={props.color || 'white'}/>
            <path d="M137.198 7.68258C138.217 7.68258 139.045 6.84355 139.045 5.81224C139.045 4.76193 138.215 3.90747 137.198 3.90747C136.181 3.90747 135.317 4.77951 135.317 5.81224C135.318 6.30817 135.516 6.78363 135.869 7.13427C136.221 7.48491 136.7 7.68211 137.198 7.68258Z"
                  fill={props.color || 'white'}/>
            <path d="M19.1096 10.0383C20.592 10.0383 22.0515 10.4017 23.3588 11.0965C24.6662 11.7912 25.781 12.7959 26.6046 14.0213C27.4281 15.2468 27.935 16.6553 28.0803 18.1221C28.2256 19.5889 28.0048 21.0686 27.4375 22.4303C26.8702 23.7919 25.9738 24.9935 24.8279 25.9284C23.682 26.8634 22.3218 27.503 20.868 27.7904C19.4141 28.0779 17.9113 28.0044 16.4928 27.5765C15.0743 27.1485 13.7838 26.3794 12.7357 25.3371C9.22168 21.8429 9.22168 16.1573 12.7357 12.6627C13.5707 11.8279 14.564 11.1661 15.6581 10.7155C16.7521 10.265 17.9251 10.0347 19.1093 10.038L19.1096 10.0383ZM19.1093 8.24439C17.6885 8.24257 16.2814 8.51991 14.9689 9.06046C13.6563 9.60102 12.464 10.3941 11.4607 11.3943C7.23665 15.5944 7.23665 22.4039 11.4607 26.6037C13.4893 28.6206 16.2405 29.7536 19.1093 29.7536C21.978 29.7536 24.7293 28.6206 26.7579 26.6037C30.9819 22.4039 30.9819 15.5944 26.7579 11.3943C25.7545 10.3941 24.5623 9.601 23.2497 9.06044C21.9371 8.51988 20.53 8.24255 19.1093 8.24439Z"
                  fill={props.color || 'white'}/>
            <path d="M18.9444 23.6801C18.3296 23.6809 17.7216 23.5528 17.16 23.3042C16.6154 23.0637 16.124 22.7186 15.7139 22.2887C15.3 21.8567 14.9725 21.3504 14.7489 20.7968C14.2798 19.6318 14.2798 18.332 14.7489 17.167C14.9725 16.6136 15.3 16.1075 15.7139 15.6758C16.124 15.246 16.6154 14.9009 17.16 14.6603C17.7219 14.4125 18.3298 14.2844 18.9446 14.2844C19.5593 14.2844 20.1672 14.4125 20.7292 14.6603C21.2735 14.901 21.7648 15.246 22.1748 15.6758C22.3317 15.8493 22.4143 16.0767 22.4053 16.3098C22.3963 16.5429 22.2962 16.7633 22.1265 16.9243C21.9567 17.0853 21.7305 17.1741 21.4959 17.172C21.2613 17.1699 21.0367 17.077 20.8699 16.913C20.6228 16.6531 20.3265 16.4444 19.9979 16.2989C19.6659 16.1534 19.3071 16.0783 18.9444 16.0783C18.5816 16.0783 18.2229 16.1534 17.8909 16.2989C17.5623 16.4444 17.266 16.6531 17.0189 16.913C16.7622 17.1807 16.5593 17.4947 16.4211 17.8381C16.1267 18.573 16.1267 19.3922 16.4211 20.1271C16.5593 20.4705 16.7622 20.7845 17.0189 21.0522C17.266 21.312 17.5624 21.5207 17.8909 21.6663C18.2229 21.8118 18.5816 21.8869 18.9444 21.8869C19.3071 21.8869 19.6659 21.8118 19.9979 21.6663C20.3264 21.5207 20.6227 21.312 20.8699 21.0522C21.0367 20.8882 21.2613 20.7953 21.4959 20.7932C21.7305 20.7911 21.9567 20.8799 22.1265 21.0409C22.2962 21.2019 22.3963 21.4223 22.4053 21.6554C22.4143 21.8885 22.3317 22.1159 22.1748 22.2894C21.7649 22.7192 21.2736 23.0643 20.7292 23.3049C20.1674 23.5532 19.5592 23.6811 18.9444 23.6801Z"
                  fill={props.color || 'white'}/>
            <path d="M19.0726 1.79357C20.4435 1.79357 21.8239 2.45863 23.1855 3.81349L28.7451 9.33948L28.8252 9.41875L34.3834 14.9455C37.1254 17.6717 37.0583 20.4697 34.3834 23.1274L23.2598 34.1872C21.9141 35.5256 20.5337 36.2075 19.1447 36.2075C17.7737 36.2075 16.3934 35.5425 15.0318 34.1872L9.47214 28.6612L9.3924 28.5816L3.83383 23.0549C1.09189 20.3287 1.15899 17.5307 3.83383 14.8726L14.9575 3.81277C16.3032 2.47513 17.6835 1.79357 19.0726 1.79357ZM19.0726 0C17.1965 0 15.381 0.85625 13.6825 2.54687L2.56063 13.6049C0.871809 15.2812 0.010622 17.0748 -0.000923048 18.9272C-0.0124681 20.8054 0.848719 22.6219 2.56063 24.3208L8.11668 29.8489L8.19677 29.9282L13.7557 35.4549C15.4543 37.1438 17.2676 38 19.1447 38C21.0219 38 22.8362 37.1438 24.5348 35.4549L35.6584 24.3951C37.3455 22.7177 38.2066 20.9274 38.2182 19.0732C38.2297 17.1935 37.3685 15.3784 35.6584 13.6778L30.0999 8.15142L30.0201 8.07106L24.4601 2.54687C22.7644 0.85625 20.9497 0 19.0726 0Z"
                  fill={props.color || 'white'}/>
            <path d="M136.509 10.7256H135.798V26.7131C135.798 28.1433 136.762 28.8919 137.714 28.8919H138.424V12.9048C138.425 11.4742 137.461 10.7256 136.509 10.7256Z"
                  fill={props.color || 'white'}/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="139.045" height="38" fill={props.color || 'white'}/>
            </clipPath>
        </defs>
    </svg>


);

export default LogoIcon;
