import Axios, { AxiosRequestConfig } from "axios";

export const axiosInstance = Axios.create();

class RestApi {
  get accessToken() {
    return localStorage.getItem("accessToken");
  }

  private baseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1`;

  private get defaultHeaders() {
    if (!this.accessToken) {
      return {};
    }

    return {
      Authorization: `Bearer ${this.accessToken}`,
    };
  }

  url(suffix?: string) {
    return this.baseUrl + suffix;
  }

  public get<T = any>(url: string, config?: AxiosRequestConfig) {
    return axiosInstance.get<T>(`${this.baseUrl}/${url}`, {
      headers: {
        ...this.defaultHeaders,
      },
      ...config,
    });
  }

  public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance.post<T>(`${this.baseUrl}/${url}`, data, {
      headers: {
        ...this.defaultHeaders,
      },
      ...config,
    });
  }

  public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance.put<T>(`${this.baseUrl}/${url}`, data, {
      headers: {
        ...this.defaultHeaders,
      },
      ...config,
    });
  }

  public patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance.patch<T>(`${this.baseUrl}/${url}`, data, {
      headers: {
        ...this.defaultHeaders,
      },
      ...config,
    });
  }
}

export default new RestApi();
