import React from "react";
import { Snackbar } from "@material-ui/core";
import { useTypedSelector } from "../../store/store.utils";
import { useDispatch } from "react-redux";
import { SnackbarActions } from "../../store/reducers/snackbar/snackbar.actions";
import { Alert } from "@material-ui/lab";

const SnackbarHandling: React.FC = () => {
  const open = useTypedSelector(state => state.snackbar.open);
  const message = useTypedSelector(state => state.snackbar.message);
  const dispatch = useDispatch();

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(SnackbarActions.close());
  };


  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert severity="error" onClose={handleClose}>{message}</Alert>
    </Snackbar>
  );
};

export default SnackbarHandling;
