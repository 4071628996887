import React from "react";
import { SvgIconInjectedProps } from "./type";


const PlusCircleIcon = (props: SvgIconInjectedProps) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C4.48578 0 0 4.48578 0 10C0 15.5142 4.48578 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48578 15.5142 0 10 0Z"
      fill={props.color ? props.color : "#CBD0DD"}/>
    <path
      d="M14.3753 10.8331H10.8336V14.3748C10.8336 14.8348 10.4603 15.2081 10.0003 15.2081C9.54022 15.2081 9.16699 14.8348 9.16699 14.3748V10.8331H5.62527C5.16522 10.8331 4.79199 10.4598 4.79199 9.99979C4.79199 9.53973 5.16522 9.1665 5.62527 9.1665H9.16699V5.62479C9.16699 5.16473 9.54022 4.7915 10.0003 4.7915C10.4603 4.7915 10.8336 5.16473 10.8336 5.62479V9.1665H14.3753C14.8353 9.1665 15.2086 9.53973 15.2086 9.99979C15.2086 10.4598 14.8353 10.8331 14.3753 10.8331Z"
      fill="#FAFAFA"/>
    <defs>
      <linearGradient id="paint0_linear" x1="4.02075" y1="-2.17573" x2="21.6262" y2="0.363732"
                      gradientUnits="userSpaceOnUse">
        <stop stopColor="#776BF8"/>
        <stop offset="1" stopColor="#4A39FF"/>
      </linearGradient>
    </defs>
  </svg>

);

export default PlusCircleIcon;
