import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const ConfigIcon = (props: Props) => {

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.5294 7.72278L17.9922 7.38861C17.8586 6.97937 17.693 6.5802 17.4976 6.19568L18.3485 4.87198C18.5005 4.63547 18.4671 4.32495 18.2684 4.12628L15.8737 1.73157C15.675 1.5329 15.3645 1.49948 15.128 1.65146L13.8043 2.50244C13.4198 2.30698 13.0206 2.14142 12.6114 2.00775L12.2772 0.470581C12.2176 0.195923 11.9743 0 11.6933 0H8.30673C8.02567 0 7.78244 0.195923 7.72278 0.470581L7.38861 2.00775C6.97937 2.14142 6.5802 2.30698 6.19568 2.50244L4.87198 1.65146C4.63547 1.49948 4.32495 1.5329 4.12628 1.73157L1.73157 4.12628C1.5329 4.32495 1.49948 4.63547 1.65146 4.87198L2.50244 6.19568C2.30698 6.5802 2.14142 6.97937 2.00775 7.38861L0.470581 7.72278C0.195923 7.78259 0 8.02567 0 8.30673V11.6933C0 11.9743 0.195923 12.2174 0.470581 12.2772L2.00775 12.6114C2.14142 13.0206 2.30698 13.4198 2.50244 13.8043L1.65146 15.128C1.49948 15.3645 1.5329 15.675 1.73157 15.8737L4.12628 18.2684C4.32495 18.4671 4.63547 18.5005 4.87198 18.3485L6.19568 17.4976C6.5802 17.693 6.97937 17.8586 7.38861 17.9922L7.72278 19.5294C7.78244 19.8041 8.02567 20 8.30673 20H11.6933C11.9743 20 12.2176 19.8041 12.2772 19.5294L12.6114 17.9922C13.0206 17.8586 13.4198 17.693 13.8043 17.4976L15.128 18.3485C15.3645 18.5005 15.675 18.4673 15.8737 18.2684L18.2684 15.8737C18.4671 15.675 18.5005 15.3645 18.3485 15.128L17.4976 13.8043C17.693 13.4198 17.8586 13.0206 17.9922 12.6114L19.5294 12.2772C19.8041 12.2174 20 11.9743 20 11.6933V8.30673C20 8.02567 19.8041 7.78259 19.5294 7.72278ZM13.5857 10C13.5857 11.9771 11.9771 13.5857 10 13.5857C8.02292 13.5857 6.41434 11.9771 6.41434 10C6.41434 8.02292 8.02292 6.41434 10 6.41434C11.9771 6.41434 13.5857 8.02292 13.5857 10Z"
        fill="currentColor"/>
    </svg>

  );
};

export default ConfigIcon;
