import React from 'react';
import styles from './SharedModal.module.scss';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { DialogProps } from '@material-ui/core/Dialog/Dialog';

interface InjectedProps {
  actionComponent?: JSX.Element;
  subActionComponent?: JSX.Element;
  handleClose?: () => void;
  title?: string;
}

type PropsType = InjectedProps & DialogProps;

const SharedModal: React.FC<PropsType> = ({
  actionComponent,
  subActionComponent,
  handleClose,
  children,
  title,
  ...dialogProps
}) => (
  <Dialog maxWidth="md" {...dialogProps}>
    <div className={styles.CloseBtnWrap}>
      {handleClose && (
        <IconButton aria-label="Delete" size="small" onClick={handleClose}>
          <Close fontSize="inherit" />
        </IconButton>
      )}
    </div>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    {actionComponent && <DialogActions>{actionComponent}</DialogActions>}
    {subActionComponent && <DialogActions>{subActionComponent}</DialogActions>}
  </Dialog>
);

export default SharedModal;
