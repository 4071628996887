import React, {Suspense, useEffect, useState} from "react";
import {RootRouting} from "./root.routing";
import classNames from "classnames";
import MainLayout from "./layout/MainLayout/MainLayout";
import {useTypedSelector} from "./store/store.utils";
import {useWindowLoad} from "./hooks/window-load.hook";
import SnackbarHandling from "./components/SnackbarHandling/SnackbarHandling";
import {Route, Switch} from "react-router-dom";
import WelcomePage from "./pages/WelcomePage/WelcomePage.lazy";
import {RouteNames} from "./constants/RouteNames";
import { CertificatePage } from './pages/certificate/index-html';

function App() {
  const userInfo = useTypedSelector(state => state.user.userInfo);
  const [showOverlay, setShowOverlay] = useState(true);
  const windowLoad = useWindowLoad();
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const hasAccessToken = localStorage.getItem("accessToken");
    setHide(windowLoad && (!hasAccessToken || !userInfo.loading));
  }, [windowLoad, userInfo.loading]);

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setShowOverlay(false);
      }, 200);
    }
  }, [hide]);


  return (
    <>
      <Suspense fallback={<div className="full-screen-overlay"/>}>
        <Switch>
          <Route component={CertificatePage} path={`/${RouteNames.VIEW_CERTIFICATE}/:certificateId`} exact={true}/>
          <Route component={WelcomePage} path={`/${RouteNames.WELCOME}`} exact={true}/>
          <MainLayout>
            <RootRouting/>
          </MainLayout>
        </Switch>
      </Suspense>
      <SnackbarHandling/>
      <div className={classNames({
        "full-screen-overlay": true,
        "hide": hide,
        "remove": !showOverlay,
      })}/>
    </>
  );
}

export default App;
