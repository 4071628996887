import React, { SVGProps } from "react";

type Props = SVGProps<any>;

const PricingPackageMenuIcon = (props: Props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.0711 2.92891C15.1823 1.04016 12.6711 0 10 0C7.32891 0 4.81766 1.04016 2.92891 2.92891C1.04016 4.81766 0 7.32891 0 10C0 12.6711 1.0402 15.1823 2.92891 17.0711C4.81762 18.9599 7.32891 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 7.32891 18.9598 4.8177 17.0711 2.92891ZM10 9.375C11.5508 9.375 12.8125 10.6367 12.8125 12.1875C12.8125 13.4718 11.947 14.557 10.7688 14.8923C10.6842 14.9164 10.625 14.9925 10.625 15.0805V15.6073C10.625 15.9439 10.3664 16.2335 10.0302 16.2493C9.67125 16.2662 9.375 15.9802 9.375 15.625V15.0804C9.375 14.9927 9.31617 14.9165 9.23176 14.8925C8.05863 14.5589 7.19535 13.4818 7.18754 12.205C7.18547 11.8641 7.45344 11.5725 7.79414 11.5628C8.1477 11.5526 8.4375 11.8362 8.4375 12.1875C8.4375 13.086 9.19988 13.8098 10.1117 13.7461C10.8814 13.6923 11.5048 13.0688 11.5586 12.2991C11.6223 11.3873 10.8985 10.625 10 10.625C8.44918 10.625 7.1875 9.36332 7.1875 7.8125C7.1875 6.52824 8.05301 5.44301 9.23117 5.1077C9.31582 5.08359 9.375 5.00746 9.375 4.91945V4.39266C9.375 4.05609 9.63363 3.76652 9.9698 3.7507C10.3288 3.73383 10.625 4.0198 10.625 4.375V4.91957C10.625 5.00734 10.6838 5.08352 10.7682 5.10754C11.9414 5.44113 12.8046 6.5182 12.8125 7.79504C12.8145 8.13586 12.5466 8.42746 12.2059 8.43723C11.8523 8.44738 11.5625 8.16379 11.5625 7.8125C11.5625 6.91398 10.8001 6.19016 9.88832 6.25391C9.11863 6.3077 8.49516 6.93121 8.44141 7.7009C8.37773 8.61273 9.10152 9.375 10 9.375Z"
        fill="currentColor"/>
    </svg>
  );
};
export default PricingPackageMenuIcon;
