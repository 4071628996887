import { UserModel } from "../user/user.models";


export enum BalanceTransactionTypes {
  SIGN_UP = "SIGN_UP",
  ADMIN = "ADMIN",
  REPLENISH = "REPLENISH",
  PAY_FOR_WORK = "PAY_FOR_WORK",
}

export interface BalanceTransaction {
  id: string;
  userId: UserModel["id"]
  prevTransactionId: null | BalanceTransaction["id"]
  amount: number;
  createdDate: Date
  type: BalanceTransactionTypes
  finalBalance: number
}
