import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UserActions } from "./store/reducers/user/user.actions";
import Auth0Lock from "auth0-lock";
import logo from "./styles/image/primary-logo.svg";
import { useHistory } from "react-router-dom";
import { RouteNames } from "./constants/RouteNames";
import { useTypedSelector } from "./store/store.utils";


const options: Auth0LockConstructorOptions = {
    auth: {
        responseType: "token id_token",
        sso: false,
      // redirectUrl: window.location.origin
    },
  theme: {
    logo,
    primaryColor: "#4A39FF",
  },
  languageDictionary: {
    title: "",
  },
  container: "auth0-login-container",
};


export const Auth0Context = React.createContext<{ auth0Lock: Auth0LockStatic, logout: (query?: string) => void }>({} as any);
export const useAuth0 = () => useContext(Auth0Context);


export const Auth0Provider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [auth0Lock, setAuth0Lock] = useState();
  const history = useHistory();
  const userInfo = useTypedSelector(state => state.user.userInfo);
  const [afterSignIn, setAfterSignIn] = useState(false);

  useEffect(() => {
    if (!(process.env.REACT_APP_AUTH_CLIENT_ID && process.env.REACT_APP_AUTH_DOMAIN)) {
      throw new Error("Invalid auth env");
    }
    setAuth0Lock(new Auth0Lock(process.env.REACT_APP_AUTH_CLIENT_ID, process.env.REACT_APP_AUTH_DOMAIN, options));
  }, []);


  useEffect(() => {
    if (!userInfo.loading && userInfo.data && afterSignIn) {
      history.push(`/${RouteNames.ROOT}`);
    }
  }, [userInfo, afterSignIn, history]);


  useEffect(() => {
    if (auth0Lock) {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        dispatch(UserActions.GetUserAsync.request());
      }
      auth0Lock.on("authenticated", async (authResult) => {
        setAfterSignIn(true);
        localStorage.setItem("accessToken", authResult.idToken);
        dispatch(UserActions.GetUserAsync.request());
      });
    }
  }, [auth0Lock, dispatch]);


  const logout = (query?: string) => {
    localStorage.removeItem("accessToken");
    auth0Lock.logout({
      returnTo: query || window.location.origin,
    });
  };


  return (
    <Auth0Context.Provider value={{
      auth0Lock,
      logout,
    }}>
      {children}
    </Auth0Context.Provider>
  );
};
