import React from "react";
import styles from "../../pages/WelcomePage/components/Header/Header.module.scss";
import {Link} from "react-router-dom";
import LogoIcon from "../icons/LogoIcon";

export const Logo: React.FC<{color?: string}> = props => {
    return (
        <div className={styles.logo}>
            <Link to="/welcome#home" aria-label="main page">
                <LogoIcon color={props.color}/>
            </Link>
        </div>
    )
};

