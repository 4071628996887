import store from "../store";
import { SnackbarActions } from "../store/reducers/snackbar/snackbar.actions";
import { axiosInstance } from '../utils/RestApi';

axiosInstance.interceptors.response.use(function (response) {
  if (response.headers["wwww-authenticate"]?.includes("USER_BLOCKED")) {
    throw new Error("User blocked");
  }
  return response;
}, function (error) {
  store.dispatch(SnackbarActions.open(error.message));
  return Promise.reject(error);
});
