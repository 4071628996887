import { useSelector } from "react-redux";
import { createAsyncAction, createReducer, RootAction, RootState } from "typesafe-actions";
import { Epic } from "redux-observable";

export function useTypedSelector<V>(selector: (state: RootState) => V, equalityFn?: (left: V, right: V) => boolean) {
  return useSelector<RootState, V>(selector, equalityFn);
}


export function createTypedReducer<V>(initialState: V) {
  return createReducer<V>(initialState);
}


export enum AsyncActionSuffix {
  FETCH_REQUEST = "FETCH_REQUEST",
  FETCH_SUCCESS = "FETCH_SUCCESS",
  FETCH_FAILURE = "FETCH_FAILURE",
}

export function createAsyncActionWithPrefix<R = undefined, S = undefined, F = Error>(prefix: string) {
  return createAsyncAction(
    `@${prefix.toUpperCase()}/${AsyncActionSuffix.FETCH_REQUEST}`,
    `@${prefix.toUpperCase()}/${AsyncActionSuffix.FETCH_SUCCESS}`,
    `@${prefix.toUpperCase()}/${AsyncActionSuffix.FETCH_FAILURE}`,
  )<R, S, F>();
};


export class RequestedReducerData<D> {
  data: D;
  loading = true;

  constructor(defaultData: D, loading?: boolean) {
    this.data = defaultData;
    this.loading = loading === undefined ? true : loading;
  }
}


export type EpicConfigured = Epic<RootAction, RootAction, RootState>;
