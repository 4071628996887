import { compose } from "redux";

declare const window: any;

const devTools = window &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export const composeEnhancers =
  process.env.NODE_ENV === "development" && devTools ?
    devTools :
    compose;
