import { useState } from "react";

export const useWindowLoad = () => {
  const [load, setLoad] = useState(false);

  window.addEventListener("load", () => {
    setLoad(true);
  });

  return load;
};
