import React, { lazy, Suspense } from "react";

const LazyVerificationPage = lazy(() => import("./VerificationPage"));

const VerificationPage = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyVerificationPage {...props} />
  </Suspense>
);

export default VerificationPage;
