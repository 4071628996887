import { createAction } from "typesafe-actions";


export enum ActionNames {
  TOGGLE_SIDENAV = "[Sidenav] Toggle"
}

export class SidenavActions {
  static toggle = createAction(ActionNames.TOGGLE_SIDENAV)();
}


